import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="Page Not Found" />
    <h1>Whoops!</h1>
    <p>You just hit a page that doesn't exist.</p>
    <p><Link to="/" style={{ color: '#000051', textDecoration: 'none' }}>&#x2190; Go to our homepage</Link></p>
  </Layout>
)

export default NotFoundPage
