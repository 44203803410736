import React from "react"

const Footer = () => (
  <footer
    style={{
      background: `#000051`,
      color: 'white',
      fontSize: '75%',
    }}
  >
    <div
      style={{
        margin: `0 auto`,
        maxWidth: 960,
        padding: `1.45rem 1.0875rem`,
      }}
    >
      <p style={{ marginBottom: '0' }}>Copyright © 2019 The Legacy Project</p>
      <p style={{ marginBottom: '0' }}>All rights reserved. No part of this web site may be used or reproduced in any manner whatsoever without written permission.</p>
    </div>
  </footer>
)

export default Footer
